@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    overflow-x: hidden;
}


/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #151526 #11111f;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #11111f;
}

*::-webkit-scrollbar-thumb {
    background-color: #151526;
    border-radius: 10px;
    border: 3px solid #11111f;
}